<template>
    <v-card>
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="ml-n3 mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h2 class="font-weight-regular">
                Send To Next Process
            </h2>
        </v-card-title>
        <v-divider class="mx-6" />
        <v-card-text
            :style="{ 'max-height': `${height}px`, 'overflow-y': 'auto' }"
        >
            <v-form v-model="valid">
                <v-data-table
                    :headers="headers"
                    :items="filteredItems"
                    class="elevation-0 mt-7"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            class="mt-0 mx-0 pt-4 pb-0 pb-4"
                            :style="{ 'background-color': '#eeeeee' }"
                        >
                            <v-col cols="6" class="d-flex align-center">
                                <h2 class="my-n3 d-flex align-center">
                                    ITEMS
                                </h2></v-col
                            >
                            <v-col cols="6" class="d-flex justify-end"
                                ><v-combobox
                                    v-model="nextProcess"
                                    hide-details
                                    prefix="Destination Process *"
                                    prepend-icon="mdi-tools"
                                    :items="availableProcesses"
                                    item-text="name"
                                    :rules="[rules.required]"
                                    required
                                    class="ma-0 pa-0"
                                    :style="{ width: '100px' }"
                                    @change="calculateValues()"
                                    :disabled="availableProcesses.length <= 1"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`header.partNumber`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="partNumberToFilter"
                            dense
                            class="pt-2"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.code`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">{{ item.code }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div>
                            <p class="my-0">{{ item.description }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.suggestedProcess`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{
                                    item.nextProcess
                                        ? item.nextProcess.name
                                        : 'There is no next process'
                                }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.available`]="{ item }">
                        <div class="d-flex justify-center">
                            <p class="my-0">
                                {{ item.available }}
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.qty`]="{ item }">
                        <div class="mb-5">
                            <v-text-field
                                v-model="item.qtyToSend"
                                hide-details
                                type="number"
                                :rules="[() => maxValue(item)]"
                            />
                        </div>
                    </template>
                    <template v-slot:[`item.assemblyWO`]="{ item }">
                        <div class="mb-5">
                            <v-combobox
                                v-model="item.destinationAssemblyWorkOrder"
                                hide-details
                                :items="item.assemblyWorkOrders"
                                item-text="code"
                                required
                                class="mb-5"
                                :disabled="
                                    !nextProcess ||
                                        item.processes[
                                            item.processes.length - 1
                                        ] != nextProcess.id
                                "
                                v-if="
                                    nextProcess && nextProcess.assemblyProcess
                                "
                            />
                        </div>
                    </template>
                </v-data-table>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn
                :loading="loading"
                color="primary"
                rounded
                class="mb-3 mr-3"
                :disabled="disableButton() || !valid"
                @click="openConfirmDialog"
            >
                NEXT
            </v-btn>
        </v-card-actions>
        <v-dialog
            :retain-focus="false"
            v-model="confirmDialog"
            persistent
            max-width="700px"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeConfirmDialog"
                        color="primary"
                        class="ml-n3 mr-2"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <h2 class="font-weight-regular">
                        Confirm Send to
                        {{ nextProcess ? nextProcess.name : '' }}
                    </h2>
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card-text>
                    <v-form v-model="valid">
                        <v-data-table
                            :headers="confirmHeaders"
                            :items="
                                workOrder.items.filter(
                                    i => i.qtyToSend && i.qtyToSend > 0
                                )
                            "
                            class="elevation-0 mt-7"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            :style="{
                                'max-height': `${height}px`,
                                'overflow-y': 'auto',
                            }"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                                    :style="{ 'background-color': '#eeeeee' }"
                                >
                                    <v-col
                                        cols="12"
                                        class="d-flex align-center"
                                    >
                                        <h2 class="my-n3 d-flex align-center">
                                            ITEMS
                                        </h2></v-col
                                    >
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.code`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.code }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">{{ item.qtyToSend }}</p>
                                </div>
                            </template>
                            <template v-slot:[`item.assemblyWO`]="{ item }">
                                <div class="d-flex justify-center">
                                    <p class="my-0">
                                        {{
                                            item.destinationAssemblyWorkOrder
                                                .code || ''
                                        }}
                                    </p>
                                </div>
                            </template>
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pt-0 pb-5">
                    <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-col cols="3" class="d-flex justify-end pa-0 ">
                            <v-btn
                                color="primary"
                                rounded
                                :disabled="!valid"
                                :loading="loading"
                                @click="sendItems"
                            >
                                SEND
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'

export default {
    name: 'MultipleSend',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        processes: { type: Object, required: true },
    },
    data: () => ({
        valid: false,
        loading: false,
        headers: [
            {
                text: 'ID',
                value: 'index',
                align: 'center',
                sortable: false,
                width: '50',
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'NEXT PROCESS',
                value: 'suggestedProcess',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'available',
                align: 'center',
                sortable: false,
                width: '130',
            },
            {
                text: 'QTY TO SEND',
                value: 'qty',
                align: 'center',
                sortable: false,
                width: '130',
            },
        ],
        confirmHeaders: [
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUANTITY TO SEND',
                value: 'qty',
                align: 'center',
                sortable: false,
            },
        ],
        rules: {
            required: v => !!v || 'The value is required',
        },

        availableProcesses: [],
        prevProcess: undefined,
        nextProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        confirmDialog: false,
        height: 0,
        partNumberToFilter: undefined,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
    }),
    computed: {
        filteredItems() {
            //filter available
            let conditions = []
            conditions.push(this.filterAvailable)

            if (this.partNumberToFilter) {
                conditions.push(this.filterForPartNumber)
            }

            //filter by process selected
            if (this.nextProcess) {
                conditions.push(this.filterForNextProcess)
            }

            if (conditions.length > 0) {
                return this.workOrder.items.filter(item => {
                    return conditions.every(condition => {
                        return condition(item)
                    })
                })
            }

            return this.workOrder.items
        },
    },
    mounted() {
        this.workOrder.items.forEach(item => {
            if (this.user.process) {
                const userProcessIndex = item.processes.findIndex(
                    p => p === this.user.process.id
                )
                item.processes.forEach((process, index) => {
                    if (index > userProcessIndex) {
                        const processIndex = this.processes.processes.findIndex(
                            p => p.id == process
                        )
                        if (processIndex > -1) {
                            const existingProcess = this.availableProcesses.find(
                                ap => ap.id == process
                            )
                            if (!existingProcess) {
                                this.availableProcesses.push(
                                    this.processes.processes[processIndex]
                                )
                            }
                        } else {
                            if (this.workOrder.assemblyWorkOrder != undefined) {
                                if (process == this.packingProcess.id) {
                                    const existingProcess = this.availableProcesses.find(
                                        ap => ap.id == process
                                    )
                                    if (!existingProcess) {
                                        this.availableProcesses.push(
                                            this.packingProcess
                                        )
                                    }
                                }
                            }
                        }
                    }
                })

                if (this.workOrder.assemblyWorkOrder == undefined) {
                    this.availableProcesses.push(this.packingProcess)
                }

                const filteredProcesses = this.availableProcesses.filter(
                    p =>
                        p.id != this.user.process.id &&
                        p.id != this.qualityProcess.id
                )
                this.availableProcesses = filteredProcesses

                this.findNextProcess(item)
            }
        })
        if (this.availableProcesses.length == 1) {
            this.nextProcess = this.availableProcesses[0]
        }
        if (this.user && this.user.process) {
            this.calculateValues()
        }
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async sendItems() {
            try {
                this.loading = true
                const itemsToUpdate = this.workOrder.items
                    .filter(i => i.qtyToSend && i.qtyToSend > 0)
                    .map(fi => ({
                        partNumberId: fi.partNumberId,
                        qtyToSend: fi.qtyToSend,
                        destinationAssemblyWorkOrder:
                            fi.destinationAssemblyWorkOrder,
                    }))
                await API.sendBatchToNextProcess({
                    items: itemsToUpdate,
                    workOrderId: this.workOrder.id,
                    prevProcess: this.user.process,
                    nextProcess: this.nextProcess,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        calculateValues() {
            try {
                if (this.nextProcess) {
                    const headerIndex = this.headers.findIndex(
                        h => h.value == 'assemblyWO'
                    )
                    if (this.nextProcess.assemblyProcess) {
                        const destinationWOHeader = {
                            text: 'DESTINATION ASSEMBLY WO',
                            value: 'assemblyWO',
                            align: 'center',
                            sortable: false,
                            width: '130',
                        }
                        if (headerIndex == -1) {
                            this.headers.push(destinationWOHeader)
                            this.confirmHeaders.push(destinationWOHeader)
                        }
                    } else {
                        if (headerIndex > -1) {
                            const confirmHeaderIndex = this.confirmHeaders.findIndex(
                                h => h.value == 'assemblyWO'
                            )
                            this.headers.splice(headerIndex, 1)
                            this.confirmHeaders.splice(confirmHeaderIndex, 1)
                        }
                    }
                }

                let items = _.cloneDeep(this.workOrder.items)
                items.forEach(item => {
                    if (item.dataProcesses) {
                        let processes = []
                        item.processes.forEach(itemProcess => {
                            const process = item.dataProcesses.find(
                                dataP => dataP.id == itemProcess
                            )
                            if (process) {
                                processes.push(process)
                            }
                        })

                        let availableProcesses = _.cloneDeep(processes)
                        const processIndex = processes.findIndex(
                            p => p.id == this.user.process.id
                        )
                        let prevProcess
                        if (processIndex > -1) {
                            prevProcess = processes[processIndex]
                            availableProcesses.splice(processIndex, 1)
                        }

                        if (prevProcess) {
                            let index = -1
                            if (
                                item.processes &&
                                item.processes.length > 0 &&
                                this.nextProcess
                            ) {
                                index = item.processes.findIndex(
                                    p => p == this.nextProcess.id
                                )
                            }

                            if (
                                (index > 0 &&
                                    item.processes[index - 1] ==
                                        this.qualityProcess.id) ||
                                (this.workOrder.assemblyWorkOrder ==
                                    undefined &&
                                    this.nextProcess == this.packingProcess)
                            ) {
                                let totalDelivered = 0
                                let totalReleased = 0
                                if (item.quality) {
                                    totalReleased = item.quality.reduce(
                                        (total, register) =>
                                            total +
                                            (Number(register.releaseQty) || 0),
                                        0
                                    )
                                }
                                if (item.deliveries) {
                                    let delivered = item.deliveries.filter(
                                        register =>
                                            register.prevProcess.id ==
                                            prevProcess.id
                                    )
                                    totalDelivered = delivered.reduce(
                                        (total, register) =>
                                            total + register.qty,
                                        0
                                    )
                                }
                                item.available = totalReleased - totalDelivered
                            } else {
                                let totalProduced = 0
                                let totalDelivered = 0
                                if (item.production) {
                                    let produced = item.production.filter(
                                        register =>
                                            register.process.id ==
                                            prevProcess.id
                                    )
                                    totalProduced = produced.reduce(
                                        (total, register) =>
                                            total + register.qty,
                                        0
                                    )
                                }
                                if (item.deliveries) {
                                    let delivered = item.deliveries.filter(
                                        register =>
                                            register.prevProcess.id ==
                                            prevProcess.id
                                    )
                                    totalDelivered = delivered.reduce(
                                        (total, register) =>
                                            total + register.qty,
                                        0
                                    )
                                }
                                item.available = totalProduced - totalDelivered
                            }
                            item.qtyToSend = Number(item.available)
                        }
                    }
                })
                this.workOrder.items = _.cloneDeep(items)
            } catch (error) {
                console.error(error)
            }
        },

        findNextProcess(item) {
            try {
                let processes = []
                item.processes.forEach(processId => {
                    if (processId == this.qualityProcess.id) {
                        if (this.workOrder.assemblyWorkOrder != undefined) {
                            processes.push(this.packingProcess)
                        }
                        processes.push(this.qualityProcess)
                    } else if (processId == this.packingProcess) {
                        if (this.workOrder.assemblyWorkOrder != undefined) {
                            processes.push(this.packingProcess)
                        }
                    } else {
                        const foundProcess = item.dataProcesses.find(
                            p => p.id == processId
                        )
                        if (foundProcess) {
                            processes.push(foundProcess)
                        }
                    }
                })

                if (this.workOrder.assemblyWorkOrder == undefined) {
                    processes.push(this.packingProcess)
                }

                const processIndex = processes.findIndex(
                    p => p.id == this.user.process.id
                )

                let nextProcess

                if (processIndex > -1 && processIndex + 1 < processes.length) {
                    nextProcess = processes[processIndex + 1]
                    if (
                        nextProcess.id == this.qualityProcess.id &&
                        processIndex + 2 < processes.length
                    ) {
                        nextProcess = processes[processIndex + 2]
                    }
                }
                item.nextProcess = nextProcess
            } catch (error) {
                console.error(error)
            }
        },

        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },

        openConfirmDialog() {
            this.confirmDialog = true
        },

        closeConfirmDialog() {
            this.confirmDialog = false
        },

        close() {
            this.workOrder.items.forEach(item => {
                delete item.qtyToSend
            })
            this.$emit('closeDialog')
        },

        disableButton() {
            return !this.workOrder.items.find(item => item.qtyToSend > 0)
        },

        onResize() {
            this.height = window.innerHeight - 260
        },

        filterAvailable(item) {
            return item.available && item.available > 0
        },

        filterForPartNumber(item) {
            return (
                item.partNumber &&
                item.partNumber
                    .toLowerCase()
                    .includes(this.partNumberToFilter.toLowerCase())
            )
        },

        filterForNextProcess(item) {
            if (
                item.nextProcess &&
                item.nextProcess.id.toLowerCase() ==
                    this.nextProcess.id.toLowerCase()
            ) {
                return true
            } else {
                delete item.qtyToSend
                return false
            }
        },
        maxValue(item) {
            return item.qtyToSend > item.available ||
                (item.qtyToSend < 0 &&
                    item.qtyToSend != undefined &&
                    item.qtyToSend != null)
                ? 'The quantity to be sent cannot be greater than the quantity available'
                : true
        },
    },
}
</script>

<style></style>
